import 'swiper/css';
import 'swiper/css/scrollbar';

import Link from 'next/link';
import React from 'react';
import { buttonVariants } from '../../UI/Button';
import { ArrowRightIcon } from '@heroicons/react/solid';
import Image from 'next/future/image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import { News as TNews } from '@/types/News';
import { Scrollbar } from 'swiper';
import { getLangCountry } from '@/helpers/languages';
import { useRouter } from 'next/router';

interface Props {
  data: TNews[];
}

const News: React.FC<Props> = ({ data }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { lang } = getLangCountry(router.query);

  return (
    <section className="section full section-y-md bg-sand-100">
      <div className="section-wrapper space-y-md">
        <div className="flex flex-col space-y-3 md:flex-row md:justify-between md:space-y-0">
          <h2 className="h3 text-left">{t('P_HOME.NEWS.TITLE')}</h2>

          <p className="h5 flex items-center space-x-3">
            <a
              href={
                lang == 'en'
                  ? `https://blog.uynsports.com/`
                  : `https://blog.uynsports.com/${lang}`
              }
              target="_blank"
              rel="noreferrer"
            >
              {t('P_HOME.NEWS.CTA')}
            </a>

            <a
              href={
                lang == 'en'
                  ? `https://blog.uynsports.com/`
                  : `https://blog.uynsports.com/${lang}`
              }
              target="_blank"
              rel="noreferrer"
              className={buttonVariants({ variant: 'secondary-dark' })}
            >
              <ArrowRightIcon className="h-4 w-4" />
            </a>
          </p>
        </div>

        <Swiper
          wrapperTag="ul"
          slidesPerView={1.2}
          spaceBetween={16}
          modules={[Scrollbar]}
          scrollbar={{ hide: false }}
          className="swiperScrollbar"
          breakpoints={{
            768: {
              slidesPerView: 2.2
            },
            1280: {
              slidesPerView: 3
            }
          }}
        >
          {data.map((news, idx) => (
            <SwiperSlide key={idx} tag="li" className="space-y-3 md:space-y-6">
              <Image
                alt=""
                src={news.image}
                width={0}
                height={0}
                sizes="100vw"
                className="w-full h-auto object-cover rounded-uyn aspect-1"
              />

              <div className="space-y-4">
                <p className="text-body">{news.title}</p>

                <Link href={news.link} aria-label={t('P_HOME.NEWS.READ')}>
                  <a className={buttonVariants({ variant: 'dark' })}>
                    {t('P_HOME.NEWS.READ')}
                  </a>
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default News;
