import React from 'react';
import Image from 'next/future/image';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { buttonVariants } from '../../UI/Button';
import LinkTo from '@/components/LinkTo';
import Link from 'next/link';

export default function BigBoxes() {
  const { t } = useTranslation();

  const sections = [
    {
      title: t('BOXES.URBAN_TRAIL'),
      men_href: '/collections/man/footwear-trekking-hiking',
      woman_href: '/collections/woman/footwear-trekking-hiking',
      child_href: '',
      src: '/v2/home/banners/1_URBANTRAIL.jpg'
    },
    {
      title: t('BOXES.GHEDINA'),
      men_href: '/collections/man/collabs-ghedina',
      woman_href: '',
      child_href: '/collections/kids/collabs-ghedina',
      src: '/v2/home/banners/2_GHEDINA.jpg'
    }
  ];

  return (
    <section className="section full section-y-md bg-white !pt-0">
      <div className="section-wrapper grid gap-6 md:grid-cols-2">
        {sections.map((section, idx) => (
          <div key={idx} className="relative">
            <Image
              alt=""
              src={section.src}
              width={0}
              height={0}
              sizes="100vw"
              className="object-cover object-center w-full h-full rounded-uyn"
            />
            <span className="absolute inset-0 bg-gradient-to-b from-60% from-transparent to-black/80 rounded-uyn" />
            <div className="absolute inset-0 flex flex-col items-center justify-end py-8 space-y-6 text-white group md:py-12">
              <div className="h5">{section.title}</div>
              <div className="flex flex-wrap justify-center xl:hidden xl:group-hover:flex">
                {section.men_href.trim() != '' && (
                  <Link href={section.men_href} aria-label={t('MAN')}>
                    <a
                      className={clsx(
                        buttonVariants({ variant: 'light' }),
                        'mx-1'
                      )}
                    >
                      {t('MAN')}
                    </a>
                  </Link>
                )}
                {section.woman_href.trim() != '' && (
                  <Link href={section.woman_href} aria-label={t('WOMAN')}>
                    <a
                      className={clsx(
                        buttonVariants({ variant: 'light' }),
                        'mx-1'
                      )}
                    >
                      {t('WOMAN')}
                    </a>
                  </Link>
                )}
                {section.child_href.trim() != '' && (
                  <Link href={section.child_href} aria-label={t('KID')}>
                    <a
                      className={clsx(
                        buttonVariants({ variant: 'light' }),
                        'mx-1'
                      )}
                    >
                      {t('KID')}
                    </a>
                  </Link>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
